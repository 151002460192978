module.exports= {
    robot: '机器人',
    robot_dealer_name1:'Binance机器人',
    robot_dealer_name2:'Okx机器人',
    robot_monitoring:'机器人监视',
    robot_base_info:'机器人基本信息',
    robot_name: '机器人名称',
    robot_run_state: '状态',
    closed: '已关闭',
    turned_on: '已开启',
    robot_state_name1: '正在运行',
    robot_state_name2: '已停止运行',
    robot_state_name3: '已删除',
    robot_state_name4: '服务切换中',
    robot_state_name5: '未绑定API',
    dormant: '休眠中',
    robot_run_time: '运行时长',
    hour:'小时',
    robot_asset_distribution:'机器人资产分布',
    robot_total_assets: '总资产',
    welcome_to: '欢迎来到',
    robot_center: '机器人中心',
    fee_relief: '燃料费减免中',
    expire: '到期',
    register_address: '注册地址',
    only_accounts_registered: '仅支持此链接注册成功的账户',

    <!-- 子组件开始 -->
    robot_products: '机器人产品',
    auto_robot: '全自动机器人',
    sime_robot: '半自动机器人',
    auto_robot_tip: '启动后机器人可自行分析市场行情并做出交易决定。',
    sime_robot_tip: '用户需要根据对行情的分析判定设置参数。',
    content1:'说明：现货全自动交易可添加3个、现货半自动交易可添加4个、智能合约交易可添加3个。',
    content3: '网格数量表示震荡区间中分割的挂单小区间数量。比如区间100-400、等差、网格数3，则是分为100-200、200-300、300-400这3个网格。等差：每相邻两档挂单价格的差值相等；等比：每相邻两档挂单价格的比值相等。',
    content4: '网格是一种在指定价格区间自动进行低买高卖的交易策略。用户设定参数后，系统分割小网格自动挂单，随着市场波动，策略低买高卖赚取波段收益。',
    yitianjjia: '已添加',
    haiketianjia: '还可添加',
    golden_triangle_arbitrage_tip: '三个币种循环交易，活跃度高',
    bb_trade_tip: '任意两个币种交易，适合横盘屯币',
    ub_trade_tip: '用USDT交易货币，获得长期理财收益',
    grid_strategy_tip: '在震荡行情中自动低买高卖，稳定套利',
    martin_strategy_tip: '单边加倍押注拉低成本，回调止盈',
    current_policy: '当前策略',
    historical_policy: '历史策略',
    paused: '已暂停',
    cycle_completed: '循环已完成',
    cycle_stopped: '循环已停止',
    auto: '全自动',
    semi: '半自动',
    put_position: '投入仓位(个)',
    create_time: '创建时间',
    Arbitrage_times: '已套利次数',
    Total_revenue: '总收益',
    yield:'收益率',
    Manually_stopped: '已手动停止',
    average_position_price: '持仓均价',
    cycled: '已循环',
    replenishment_depth: '补仓深度',
    not_yet: '暂无',
    first_order: '首单',
    di: '第',
    ci: '次',
    floating_profit_loss: '浮动盈亏',
    current_price: '当前价格',
    strategy_details: '策略详情',
    create_strategy: '创建策略',
    pause_strategy: '暂停策略',
    open_strategy: '开启策略',
    del_strategy: '删除策略',
    stop_strategy: '停止策略',
    restart_strategy: '重启策略',
    pause: '暂停',
    stop: '停止',
    start: '开启',
    restart: '重启',
    total: '共',
    strip: '条',
    del: '删除',
    number_of_cycles: '已循环次数',
    add: '添加',
    sj_tip: '一组黄金三角套利包含3个交易对。',
    select: '选择',
    binance: '币安',
    okx: '欧易',
    space_currency: '持仓币种',
    supportive: '支持的',
    transaction_pair: '交易对',
    please_select: '请选择',
    set_transaction_pair_position: '设置交易对仓位',
    sure: '确定',
    available: '可用',
    ub_tip: '机器人用于该币种交易的金额（USDT)，建议仓位总额不要超过',
    available_balance: '可用余额',
    grid_hokder: '请选择要投入的币种',
    grid_buy_holder: '请选择要买入的币种',
    grid_section1: '输入预判的价格震荡区间',
    grid_section2: '针对以上区间，确定分割的网格数量',
    grid_section3: '确定投入网格策略的金额',
    interval_lowest_price: '区间最低价',
    interval_maximum_price: '区间最高价',
    interval_tip: '区间最高价需大于区间最低价',
    grid_num: '网格数量',
    equal_difference: '等差',
    equal_ratio: '等比',
    grid_tip: '价格区间内的单网格收益率的预估值，已扣除手续费。',
    grid_ratio_tip1: '为保证网格收益，单格利润率需大于1%',
    grid_ratio_tip2: '为保证网格收益，单格利润率需大于0%',
    input_amount_holder: '输入金额',
    input_amount_tip1: '投入金额不能小于',
    input_amount_tip2: '投入金额不能超出可用金额',
    Estimated_ratio: '预计单网格利润率',
    second_confirm: '下单二次确认',
    basic_settings: '基础设置',
    grid_mode: '网格模式',
    donot_prompt: '不再提示',
    cancel: '取消',
    confirm: '确认',
    delete: '删除',
    strategy: '策略',
    del_grid_tip: '删除网格策略后，机器人将停止对策略的监控及运行。',
    stop_martin_tip: '停止马丁策略后，机器人将停止对策略的监控及运行。',
    strategy_cancel_order: '策略并撤单',
    strategy_no_cancellation: '策略但不撤单',
    strategy_cancel_order_radio1: '将撤掉该策略在交易所的相关挂单，因策略没有完全执行可能产生亏损',
    strategy_cancel_order_radio2: '将撤掉该策略在交易所的相关挂单',
    strategy_cancel_order_radio2_tip: '撤单需要20-60分钟，为避免撤单不彻底，期间请勿关闭机器人',
    strategy_no_cancellation_radio1: '不撤掉该策略在交易所的相关挂单，挂单到相应价位会继续成交产生利润',
    strategy_no_cancellation_radio2: '不撤掉该策略在交易所的相关挂单',
    quantity_transfinite: '数量超限',
    quantity_transfinite_auto0: '普通用户最多可添加1个交易对（含UB、BB），升级为VIP可添加3个交易对、升级为SVIP可添加8个交易对。',
    quantity_transfinite_auto1: 'VIP最多可添加3个交易对（含UB、BB），升级为SVIP可添加8个交易对。',
    quantity_transfinite_auto2: 'SVIP最多可添加8个交易对（含UB、BB、黄金三角套利），超出不支持添加。',
    quantity_transfinite_auto3: 'YSVIP最多可添加12个交易对（含UB、BB、黄金三角套利），超出不支持添加。',
    quantity_transfinite_semi0: '普通用户最多可添加3个半自动交易对，升级为VIP可添加8个交易对、升级为SVIP可添加12个交易对。',
    quantity_transfinite_semi1: 'VIP最多可添加8个半自动交易对，升级为SVIP可添加12个交易对。',
    quantity_transfinite_semi2: 'SVIP最多可添加12个半自动交易对，超出不支持添加。',
    quantity_transfinite_semi3: 'YSVIP最多可添加20个半自动交易对，超出不支持添加。',
    next_time: '下次再说',
    i_know: '我知道了',
    open_svip: '开通SVIP',
    sj_trade_tip: '黄金三角交易提示',
    sj_trade_content: '黄金三角套利交易仅限SVIP用户使用，开通即享9大特权。',
    open_svip_tip: 'SVIP尊享黄金三角套利交易、BB交易、UB交易共8个交易对，联系客服即可开通哦',
    order_amount_must_be: '下单金额必须',
    enter_content: '请输入内容',
    position_currency_position: '持仓币种仓位',
    transaction_currency_position: '交易币种仓位',
    is_deal_pair: '的交易对',
    enter: '请输入',
    is_trading_position: '的交易仓位',
    added_success: '添加成功',
    supported_transaction_currency: '支持的交易币种',
    supported_transaction_pairs: '支持的交易对',
    enter_the_bin_amount: '请输入仓位金额',
    position_amount_must_be: '仓位需',
    number_of_grids_cannot_be_less_than: '网格数量不能小于',
    number_of_grids_cannot_be_more_than: '网格数量不能大于',
    single_grid_profit_margin_must_be_greater_than: '单网格利润率必须大于',
    input_amount_and_available_amount: '投入金额不能大于可用金额',
    are_you_sure_del_sj: '确定要删除这条黄金三角交易吗？',
    // are_you_sure_del_bb: '确定要删除这条BB交易吗？',
    are_you_sure_del_bb: '删除BB交易后，机器人将停止对策略的监控及运行并强制撤掉全部挂单。',
    // are_you_sure_del_ub: '确定要删除这条UB交易吗？',
    are_you_sure_del_ub: '删除UB策略后，机器人将停止对策略的监控及运行，如需撤单请至交易所操作。',
    del_success: '删除成功',
    you_sure_you_suspend_strategy_martin: '确定要暂停这条马丁策略吗？',
    you_sure_you_suspend_strategy_grid: '确定要暂停这条网格策略吗？',
    policy_will_stop_completed: '策略执行完停止补单后将自动停止',
    policy_will_stop_clearance_sale: '策略执行完清仓卖出后将自动停止',
    only_input: '只投入',
    input_together: '同时投入',
    <!-- 子组件结束 -->

    robot_ranking: '机器人排行榜',
    allow_other_users_to_view_my_policies: '允许其他用户查看我的仓位',
    // billing_list_of_week: '本周开单榜',
    billing_list_of_week: '本周收益率榜',
    revenue_list_of_week: '本周收益榜',
    my_ranking: '我的排名',
    not_listed: '未入榜',
    see_policies: '看TA仓位',
    no_data: '暂无数据',
    all: '全部',
    sj:'黄金三角',
    bb: 'BB',
    ub: 'UB',
    grid: '网格',
    martin: '马丁',
    order: '单',
    button_sure: '確 定',
    everyone_space: 'TA的仓位设置',
    cumulative_billing_volume_week: '本周累计开单量',
    golden_triangle_arbitrage: '黄金三角套利交易',
    bb_trade: '现货双向AI交易',
    ub_trade: 'UB交易',
    transaction_pair_added: '已添加交易对',
    init_space: '初始仓位',
    side: '方向',
    trade_currency: '交易币种',
    grid_strategy: '现货网格交易',
    martin_strategy: '现货马丁交易',
    deal_pair: '交易对',
    input_amount: '投入金额',
    input_coin: '投入币种',
    Price_range: '价格区间',
    number_of_grids: '网格数量',
    policy_type: '策略类型',
    full_order_position: '满单仓位',
    stop_ratio: '止盈比例',
    strategy_type_name0: '仓位马丁策略',
    strategy_type_name1: '盈利预期马丁策略',
    strategy_type_name2: '仓位&盈利预期双马丁策略',
    // close_robot_sure: '确定关闭机器人吗？',
    close_robot_sure: '关闭机器人后将停止对策略的监控及运行',
    bind_binance_api_tip: '请先前往“安全设置”绑定Binance的API',
    bind_okx_api_tip: '请先前往“安全设置”绑定OKX的API',
    bind_api_tip: '请先前往“安全设置”绑定交易所的API',

    button_start_stop_name1: '启动机器人',
    button_start_stop_name2: '关闭机器人',
    button_start_stop_name3: '已删除',
    button_start_stop_name4: '服务切换中',
    button_start_stop_name5: '未绑定API',

    <!-- 子页面开始 -->
    policy_information: '策略信息',
    policy_delegation: '策略委托',
    initial_held_assets: '初始持有资产',
    current_delegation: '当前委托',
    history_delegation: '历史委托',
    history_transaction: '历史成交',
    start_date: '开始时间',
    end_date: '结束时间',
    complete_deal: '完全成交',
    price: '价格',
    num: '数量',
    number_of_transactions: '成交数量',
    entrusted_quantity: '委托数量',
    date: '日期',
    time: '时间',
    average_transaction_price: '成交均价',
    end_time_cannot_less_than_start: '结束时间不能小于开始时间',
    start_time_cannot_creater_than_end: '开始时间不能大于结束时间',
    buy: '买',
    sell: '卖',
    cancelled: '已撤单',
    pending_order: '挂单中',
    completed: '已完成',
    transaction_price: '成交价格',
    revenue_details: '收益详情',
    grid_hanging_list: '网格挂单',
    policy_info: '策略信息',
    transaction_record: '成交记录',
    total_annualization: '总年化',
    grid_profit: '网格利润',
    grid_profit_tip: '每组已完成成交的网格产生的收益总和',
    floating_profit_loss_tip: '除去网格利润以外，持有交易币种产生的浮动盈亏',
    investment_amount: '投资额',
    investment_amount_tip: '开始策略时投入币种的总和',
    currently_held_assets: '当前持有资产',
    single_grid_amount: '单网格金额',
    single_grid_num: '单网格数量',
    expected_single_grid_profit_margin: '预期单网格利润率',
    price_when_strategy_started: '策略开启时价格',
    number_of_pending_orders: '挂单成交次数',
    latest_market_price: '市场最新价格',
    pay_the_bill: '买单',
    selling_order: '卖单',
    number: '编号',
    entrusted_price: '委托价格',
    trading_up_and_down: '涨跌多少后成交',
    data_acquisition_in_progress: '数据获取中',
    transaction_details: '成交详细',
    buy_in: '买入',
    sell_out: '卖出',
    entrusted_time: '委托时间',
    transaction_time: '成交时间',
    transaction_volume: '交易额',
    service_charge: '手续费',
    del_grid_strategy: '删除网格策略',
    equal_difference_value: '等差差值',
    total_arbitrage_times_grid: '网格总套利次数',
    negative_tip: '网格利润减去手续费后为负数，建议重新设置网格策略。',
    operation: '操作',
    trading_direction: '交易方向',
    transaction_fee: '交易手续费',
    shortcut_settings: '快捷设置',
    cyclic_recording: '循环记录',
    transaction_settings: '交易设置',
    stop_replenishment: '停止补单',
    start_replenishment: '开启补单',
    clearance_sale: '清仓卖出',
    one_click_replenishment: '一键补仓',
    full_stop: '满单止损',
    tracking_interference_stop_callback: '追踪止盈回调',
    tracking_replenishment_callback: '追踪补仓回调',
    secondary_cycle: '次循环',
    in_progress: '进行中',
    interference_stop_completed: '止盈完成',
    stop_loss_complete: '止损完成',
    completion_last_transaction_time: '完成/最后成交时间',
    income_amount: '收益金额',
    stop_martin_strategy: '停止马丁策略',
    stop_policy_and_cancel_order: '停止策略并撤单',
    stop_strategy_no_cancellation: '停止策略但不撤单',
    stop_replenishment_tip: '停止补单后价格到补单位置将不再加仓',
    stop_replenishment_radio1: '仅本次循环停止补单',
    stop_replenishment_radio1_tip: '在本次循环中不再加仓，不影响下次循环的运行',
    stop_replenishment_radio2: '本次循环停止补单且完成后停止策略',
    stop_replenishment_radio2_tip: '本次循环不再加仓且完成后停止策略不在循环',
    clearance_sale_tip: '当前全部仓位将以市价挂单卖出',
    clearance_sale_radio1: '仅本次循环清仓卖出',
    clearance_sale_radio1_tip: '当前仓位清仓卖出，不影响下次循环的运行',
    clearance_sale_radio2: '本次循环清仓卖出并停止策略',
    clearance_sale_radio2_tip: '当前仓位全部卖出且停止策略不再循环',
    one_click_replenishment_tip: '本次补仓仅作用于当前循环，且不影响策略的补仓配置。因补仓命令会在追踪止盈追踪补仓等进程结束后执行，可能会有一定延迟。',
    replenishment_position: '补仓仓位',
    replenishment_position_tip1: '补仓金额不能小于最小金额',
    replenishment_position_tip2: '补仓金额不能大于可用余额',
    replenishment_position_price: '补仓价格',
    stop_supplement_isrunning: '循环正在运行中，请稍后再试！',
    open_supplement_open_tip: '开启补单后价格到补单位置将继续买入，并按设置进行下一次循环',
    supplement_opened: '补单已开启',
    operation_success: '操作成功',
    set_success: '设置成功',
    input_replenishment_position: '请输入补仓金额',
    status_modified_success: '状态修改成功',
    replenishment_amount_required: '补仓金额需',
    total_profit: '总利润',
    done: '已做',
    trend_cycle: '趋势周期',
    minutes: '分钟',
    first_order_limit: '首单额度',
    replenishment_times: '补仓次数',
    times_of_cycles: '循环次数',
    stop_loss_ratio: '止损比例',
    infinite: '无限',
    deal_record: '交易记录',
    see: '查看',
    replenishment: '补仓',
    interference: '止盈',
    loss: '止损',
    <!-- 子页面结束 -->

    <!-- new -->
    are_you_sure_del_grid: '确定要删除这条网格策略吗？',
    are_you_sure_del_martin: '确定要停止这条马丁策略吗？',
    save: '保存',
    limitless: '无限',
    open: '开',
    close: '关',
    sj_error_tip: '您当前有其他全自动策略正在初始化，您可以先“保存”这条策略稍后再手动开启',
    position_set_tip: '建议设置仓位不超过',
    transaction_tips: '此交易赚取的是',
    cancel_reset: '取消重启',
    cancel_edit: '取消修改',
    start_: '启动',
    restart_: '重新启动',
    save_changes: '保存修改',
    add_martin: '添加马丁策略',
    reset_martin: '重启马丁策略',
    set_trade: '交易设置',
    save_and_start: '保存并启动',
    no_more_than_available_balance: '建议设置不超过可用余额',
    batch_open: '批量开启',
    select_all: '全选',
    del_grid_tip_stop: '机器人关闭状态删除策略将不执行撤单，如需撤单至交易所手动撤单',
    stop_martin_tip_stop: '因机器人处于关闭状态，无法撤掉挂单需至交易所手动撤单。',
    please_fill_in_any_position: '至少填一份仓位',
    save_success: '保存成功',
    go_to_strategy_list_enable: '需要至列表批量开启策略！',
    set_up_multiple_groups_golden: '如果设置多组黄金三角含有相同币种，建议到列表同时批量开启策略以保证收益最大化！',
    serial_number: '序号',
    fee_relief_eight: '燃料费8折减免中',
    state: '状态',
    initializing: '正在初始化',
    go_to_exchange_recharge: '请至交易所充值',
    purchase_price: '入场价',
    delete_sj_for_closed_bot: '因机器人处于关闭状态，无法撤掉挂单需至交易所手动撤单。',
    delete_sj_for_started_bot: '删除黄金三角交易后，机器人将停止对策略的监控及运行并强制撤掉全部挂单。',
    min_position: '建议设置仓位不小于',
    contract_strategy_tip: '多空对冲震荡套利，风险可控',
    grid_contract_strategy: '合约AI交易',
    input_grid_contract_amount: '设置投入策略的金额（USDT)',
    futures_currently_held: '当前持有的合约',
    trading_place: '交易所',
    long_order: '多单',
    short_order: '空单',
    cont: '张',
    earnings_change: '收益变化',
    pause_contract_tip: '暂停智能合约交易后，机器人将停止对策略的监控及运行。',
    del_contract_tip: '删除智能合约交易后，机器人将停止对策略的监控及运行。',
    pause_risk_tip: '我已知晓风险并自愿承担',
    liquidation_risk_tip: '有爆仓风险，需要去交易所手动撤单、平仓',
    select_risk_tip: '请选择承担风险',
    are_you_cancel_order: '是否全部撤单',
    cancel_contract_all_no_deal: '将自动撤销所有当前合约的未成交订单',
    retain_contract_all_no_deal: '未成交订单将被保留在您的合约账户',
    are_you_close_position: '是否全部平仓',
    yes: '是',
    no: '否',
    closed_market_price_all: '将以市价平掉当前合约的所有仓位',
    retain_market_price_all: '未完结仓位将被保留在您的合约账户',
    del_hedge: '删除智能合约交易后，机器人将停止对策略的监控及运行，但不会撤掉挂单、不平仓，如需操作请至交易所手动处理。',
    add_position: '修改',
    add_contract_tip: '1000≤仓位>6000，币种:DOT，杠杆：10x\n' +
        '6000≤仓位>20000，币种:BTC，杠杆：20x\n' +
        '仓位≥20000，币种:BTC，杠杆：10x\n',
    margin_rate: '保证金率',
    add_position_long: '修改仓位',
    more_initial_amount: '输入的仓位不能小于初始仓位',
    supreme_hedge: '智能合约',
    add_position_success: '仓位修改成功',
    business_line: '业务线',
    perpetual: '永续',
    position_type: '类型',
    cross: '全仓',
    order_Value: '委托价值',
    unfilled: '未成交',
    old: '原仓位',
    trading_position: '仓位',
    positions: '当前仓位',
    position_history: '历史仓位',
    long: '多',
    short: '空',
    total_position: '持仓量',
    avg_price: '开仓均价',
    IMR: '初始保证金',
    mark_price: '标记价格',
    liq_price: '预估强平价',
    pl: '收益',
    opened: '开仓时间',
    avg_cost: '成本均价',
    avg_close_price: '平仓均价',
    max_open_int: '最大持仓量',
    closed_position: '已平仓量',
    closed_time: '全平时间',
    partial_close: '部分平仓',
    close_all: '全部平仓',
    stop_profit_stop_loss: '止盈止损',
    limit_market: '限价 | 市价',
    billing_quantity: '开单量',
    position_limit_description: '仓位限制根据交易所的最小交易量限制结合机器人设计计算得出。',
    select_hedge_coin: '选择交易币种',
    position_floating: '持仓浮动',
    profit_withdrawn: '已提取利润',
    available_balance_hedge: '建议设置不超过可用余额',
    test_is_full_tip: '本期内测名额已满，下期内测将于6月开放，敬请期待哦。',
    risk_setting: '风险设置',
    hedge_stop_loss_tip: '仓位不足时是否止损',
    hedge_stop_loss_tip_two: '（基于最后一次加仓的价格止损）',
    hedge_stop_surplus_tip: '最高到达盈利（到达后策略将停止）',
    not_stop_loss: '不止损',
    not_stop_surplus: '不止盈',
    hedge_risk_tip_title: '合约风险提示',
    hedge_risk_tip_content: '合约交易有损失全部本金的风险',
    hedge_risk_tip_content_two: '策略运行过程中可能会有一定的浮亏，为避免产生不必要的亏损请谨慎操作！',
    hedge_risk_tip: '我已知晓',
    hedge_started_tip: '添加智能合约交易时需要先关闭机器人再创建策略',
    hedge_create_dialog_tip: '燃料欠费会导致机器人关闭，停止对策略的监控运行，对合约策略会产生较大风险。为避免此种情况的发生，限制开启策略时燃料费大于本金的10%，例策略仓位为900USDT,那么燃料费需大于等于90USDT。燃料费不足时将有短信/邮件或客服提醒；用户可享受最近一次燃料费充值10%的授信额度；燃料费可以随时划转提取。',
    input_multiple: '请输入300的倍数',
    enter_trade: '进入交易',
    no_trade_leave: '暂不交易，离开',
    enter_trade_tip: '请先认真阅读并确认勾选《UPlus智能合约交易协议》',
    hedging_contract_trading_risk_title: 'UPlus智能合约交易风险提示',
    hedge_detail_tip1: '仅统计划转至交易账户的利润',
    hedge_detail_tip2: '策略交易一次产生利润为开1单',
    hedge_detail_tip3: '收益率=总收益/投入金额',
    hedge_detail_tip4: '所持仓位未平仓的浮动盈亏',
    hedge_detail_tip5: '策略所设置的仓位资金',
    hedge_detail_tip6: '交易所交易账户的USDT数量',
    exchange_assets: '交易所资产',
    funds_cannot_more_balance: '设置资金不能超过可用余额',
    cannot_more_balance: '不可超过可用余额',
    funds_set_tip: '为保证资金使用效率，建议设置为300的倍数',
    warn1: '使用智能合约交易是复杂且有风险的。 您在交易合约时可能会实现大幅收益或损失，因此在开始交易前请做好充分准备。 小幅价格波动也可能对您的交易产生很大的影响。',
    warn2: '当UPlus账户燃料费低于-10U时，智能合约交易将自动关闭，届时会产生较高风险。',
    warn3: '请务必在开始交易前仔细阅读《UPlus智能合约交易协议》。',
    warn4: '我已阅读和了解相关协定，并已知晓风险',
    Click_view: '点击查看',
    UPlus_Smart_furures_Trading_Agreement: '《UPlus智能合约交易协定》',
    stop_loss: '止损',
    please_select_currency: '请选择交易币种',
    loss_warn_tip1: '策略风险较高时会平掉部分仓位以释放保证金抵御风险，平掉仓位的浮亏会转为实亏。后续盈利优先补亏损，补亏损不消耗燃料费。',
    loss_warn_tip2: '想要扛单至行情回调可选择不止损。策略使用的是全仓保证金，可能会亏掉交易账户仓位设置以外的保证金，需留意风险。',
    hedge_balance_tip_b: 'Binance合约账户资金减其他策略占用资金',
    hedge_balance_tip_o: 'Okx交易账户资金-其他策略占用资金',
    Abnormal_Reminder: '异常提醒',
    warn_dialog_title: "机器人运行异常 ,请及时修复",
    Recharge: "去充值",
    fill_up_positions: "补齐仓位",
    input_fillup_position: "请输入补齐金额",
    amount: "应补金额",
    input_position: "投入仓位",
    available_fill_position: "可用资金",
    make_up_loss_ratio: "补亏损比例",
    risk_level: "风险等级",
    spot_limit_tip: "您的现货全自动交易已经添加上限，请删除后再继续添加！",
    futures_limit_tip: "您的合约交易已经添加上限，请删除后再继续添加！",
    semi_limit_tip: "您的现货半自动交易已经添加上限，请删除后再继续添加！",
    minimum_position: "策略仓位预览",
    input_min_position: "最小投入仓位",
    futures_products: "合约产品",
    Spot_product: "现货产品",
    futures_spot: "期现产品",
    futures_spot_strategy: '期现AI交易',
    orders: '委托',
    futures_tip1_3: "风险不敏感，追求高额收益，可忍受较多本金损失",
    no_stop_loss: "不止损",
}
