export default {
    okx_address(){
        // return 'https://www.dflepam.cn/join/UPLUS2'
        // return 'https://www.sdchizhengjixie.com/join/UPLUS2'
        // return 'https://www.ouyicn.press/join/10523149'
        // return 'https://www.cnouyi.care/join/UPLUS3'
        // return 'https://www.tianyena.com/join/UPLUS3'
        return 'https://www.cnouyi.ninja/join/UPLUS3'
    },
    binance_address(){
        return 'https://www.binancezh.top/zh-CN'
    },

    download_android_address(){
        // return 'https://u.ppx6.com/5xvnjm'
        return 'https://fyoss.fy5.co/d7mt2d'
    },

    download_ios_address(){
        // return 'https://u.ppx6.com/5aad59'
        return 'https://fyoss.fy5.co/d7mt2d'
    }
}
